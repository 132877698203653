import React from 'react';
import { makeStyles, Typography, Box, Grid, useTheme } from '@material-ui/core';
import StyledButton from '../atoms/StyledButton';
import PropTypes from 'prop-types';
import computerImage from '../../static/images/computer-and-ellipsis.png';
import ShowInMobile from '../atoms/ShowInMobile';
import UseDrawerContext from '../../contexts/DrawerContext';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    borderRadius: '16px',
    height: '373px',
    background: theme.palette.black.main,

    [theme.breakpoints.down('md')]: {
      height: 'inherit'
    },
  },

  texts: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '373px',
    color: theme.palette.primary.soft,
    padding: '0 30px',
    width: '50%',
    '& .MuiTypography-h6': {
      padding: '15px 0',
      color: theme.palette.primary.soft,
      fontSize: '16px'
    },

    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      width: '100% !important',
      textAlign: 'center',
      height: '100%',
      padding: '32px 16px',
    },
  },
  photo: {
    marginTop: '-1%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }

}));

export default function MainLinkCard({ color }) {
  const classes = useStyles();
  const theme = useTheme();
  const { openProcessCreateAccount } = UseDrawerContext();

  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item className={classes.texts}>
          <Typography variant="h4" component="h2" color={'inherit'}>
            Somos mais que experts. A gente manja de contabilidade!
          </Typography>
          <Typography variant="h6" component="h3" color={'inherit'}>
          Quer abrir sua empresa e não sabe por onde começar? Fale com nossa equipe, que manja de contabilidade!
          </Typography>
            <StyledButton
              text="Abra sua empresa"
              type="main"
              style={{ width: '70%' }}
              onClick={() => openProcessCreateAccount()}
            />
        </Grid>
        <Grid item className={classes.photo}>
          <ShowInMobile breakpoint={theme.breakpoints.values.md}>
            <img src={computerImage} alt="Imagem de um computador" width={'100%'} height="100%"/>
          </ShowInMobile>
        </Grid>
      </Grid>
    </Box>
  );
};

MainLinkCard.propTypes = {
  color: PropTypes.string
};
