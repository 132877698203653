import React from 'react';
import Index from '../components/pages/index';
import { Box } from '@material-ui/core';

const IndexPage = () => {
  return (
  <>
      <Box>
        <Index />
      </Box>
  </>
  );
};

export default IndexPage;
