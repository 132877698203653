import React from 'react'
import WrapperLinkCards from '../organisms/WrapperLinkCards';
import SEO from '../atoms/SEO'

export default function index() {
  return (
    <>
      <SEO />
      <WrapperLinkCards />
    </>
  )
}
