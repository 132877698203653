import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import Journal from '../../static/images/journal.png';
import Woman from '../../static/images/woman.png';
import ArrowRightIcon from '../atoms/icons/ArrowRightIcon';
import ShowInMobile from '../atoms/ShowInMobile';

const useStyles = makeStyles((theme) => ({

  container: {
    width: '100%',
    borderRadius: '16px',
    background: theme.palette.primary.lightest,

  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      display: 'inherit'
    }
  },
  texts: {
    padding: '48px 0 48px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& .MuiTypography-h6': {
      color: theme.palette.gray.darkGray,
      fontWeight: 'bold'
    },

    '& .MuiTypography-subtitle1': {
      color: theme.palette.gray.midGray,
    },

    [theme.breakpoints.down('md')]: {
      height: '227px',
      alignItems: 'center',
      padding: '32px 16px',
      textAlign: 'center',
      width: '100%',
    }
  },
  photo: {
    backgroundImage: 'url(/ellipsis.svg)',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '& span': {
      marginRight: '32px'
    }
  }

}));

const typeCard = {
  journal: {
    title: 'Por dentro do contábil',
    subtitle: 'Muita dica de contabilidade e conteúdo para empreendedores',
    link: 'Conheça nosso blog',
    image: Journal,
    href: 'https://conube.com.br/blog/',
    alt: 'Pessoa segurando o jornal'
  },
  woman: {
    title: 'O mundo dos empreendedores',
    subtitle: 'Veja como nossa contabilidade pode ajudar seu negócio crescer',
    link: 'Saiba mais',
    image: Woman,
    href: 'https://www.conube.com.br/',
    alt: 'Mulher com um leve sorriso'
  }
};

export default function LinkCard({ type }) {
  const classes = useStyles();
  const theme = useTheme();

  const { title, subtitle, link, image, href, alt } = typeCard[type];

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box className={classes.texts}>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>

          <Typography variant="subtitle1" component="h3">
            {subtitle}
          </Typography>
          <a className={classes.link} href={href}>
            <span>{link}</span>
            <ArrowRightIcon/>
          </a>
        </Box>
        <Box className={classes.photo}>
          <ShowInMobile breakpoint={theme.breakpoints.values.md}>
            <img src={image} alt={alt} />
          </ShowInMobile>
        </Box>
      </Box>
    </Box>
  );
}

LinkCard.propTypes = {
  type: PropTypes.string.isRequired
};

LinkCard.defaultProps = {
  type: 'Woman'
};
