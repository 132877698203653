import React from 'react';
import { Grid } from '@material-ui/core';
import LinkCard from '../molecules/LinkCard';
import MainLinkCard from '../molecules/MainLinkCard';

export default function WrapperLinkCards() {
  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={6}>
        <LinkCard type={'journal'} />
      </Grid>
      <Grid item sm={12} md={6}>
        <LinkCard type={'woman'} />
      </Grid>
      <Grid item md={12}>
        <MainLinkCard />
      </Grid>
    </Grid>
  )
}
